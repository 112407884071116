import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../../store/app/store';

export const NavItems = () => {
  const appStore = useStore($appStore);

  return (
    <div className="navItems">
      <Link
        to={`${appStore.language}/small-tragedies`}
        className={`navItems-item ${appStore.currentPage === 'small-tragedies' ? 'selected' : ''}`}
      >
        LP & Video
      </Link>
      <Link
        to={`${appStore.language}/about`}
        className={`navItems-item ${appStore.currentPage === 'about' ? 'selected' : ''}`}
      >
        About us
      </Link>
      <Link
        to={`${appStore.language}/performances`}
        className={`navItems-item ${appStore.currentPage === 'performances' ? 'selected' : ''}`}
      >
        Performances
      </Link>
      <Link
        to={`${appStore.language}/media`}
        className={`navItems-item ${appStore.currentPage === 'media' ? 'selected' : ''}`}
      >
        Media
      </Link>
    </div>
  );
};
